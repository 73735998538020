import Checked from "../../../shared/assets/images/checked.png";
import cls from "./checkBoxField.module.css";
const CheckBoxField = ({ name, value, onChange, children, error }) => {
    const handleChange = () => {
        onChange({ name: name, value: !value });
    };

    return (
        <div className={cls.checkboxWrapper}>
            <label className={cls.testL}>
                <input
                    type="checkbox"
                    checked={value}
                    value=""
                    onChange={handleChange}
                    name={name}
                />
                <img className={`${cls.checkedImg} ${value ? "" : "hidden"}`} src={Checked} alt=""/>
                <span>{children}</span>
            </label>
            {error && (
                <div className="invalidFeedback">
                    {error}
                </div>
            )}
        </div>
    );
};

export default CheckBoxField;
