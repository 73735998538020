import AboutImg from "../../shared/assets/images/aboutUs.png";
import ArrowUp from "../../shared/assets/images/ArrowUp.png";
import { useState } from "react";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import cls from "./About.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ScrollButton from "../../shared/ui/ScrollButton/ScrollButton";
const AboutUs = () => {
    const [visible, setVisible] = useState(false)
    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 800){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };


    window.addEventListener('scroll', toggleVisible);

    return (
        <div id="aboutUs" className="about-section">
            <div style={{display: visible ? "flex" : "none"}} className={cls.up} onClick={() => window.location.href = "#main"}>
                <ScrollButton src={ArrowUp} />
            </div>
            <div className="container container--main">
                <div className={cls.aboutWrapper}>
                    <MarginBottomContainer margin={22}>
                        <Title title="About Us" />
                    </MarginBottomContainer>

                    <MarginBottomContainer margin={90}>
                        <div className={cls.aboutWrapper__info}>
                            <div style={{flexBasis:"50%", marginRight: "33px"}}>
                                <ParagraphSection>
                                    We're a group of app enthusiasts who decided it was time to make the apps we've always wanted to use. By combining our skills in technology and design, we focus on making apps that are easy to use and genuinely useful. Every app we develop is crafted with the user in mind, aiming to offer a seamless and enjoyable experience.
                                </ParagraphSection>
                            </div>

                            <div style={{flexBasis:"50%"}}>
                                <ParagraphSection>
                                    Our studio thrives on innovation, embracing new technologies and ideas to desig that are both unique and captivating. With every project, we aim to deliver unparalleled experiences that stand the test of time.
                                </ParagraphSection>
                            </div>
                        </div>
                    </MarginBottomContainer>
                    <div className={cls.aboutWrapper__image}>
                        <img className="img" src={AboutImg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
