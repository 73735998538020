import Airplane from "../../shared/assets/images/airplan.png";
import Letter from "../../shared/assets/images/letter.png";
import cls from "./ContactUs.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import Title from "../../shared/ui/Title/Title";

const ContactUs = () => {
    return (
        <div id="contact" className="contact-section">
            <div className="container container--main">
                <div className={cls.contactWrapper}>
                    <div className="contact-wrapper__info">
                        <MarginBottomContainer margin={29}>
                            <Title title="Contact Us" />
                        </MarginBottomContainer>

                        <div className="contacts">
                            <p className={cls.pWeak}>
                                <span className={cls.pStrong}>info@expresslabapps.com</span> - general questions
                            </p>
                            <p className={cls.pWeak}>
                                <span className={cls.pStrong}>expreslabsg@gmail.com</span> - <span>cooperation questions</span>
                            </p>
                        </div>

                    </div>
                    <div className={cls.contactWrapper__image}>
                        <img className={cls.airplane} src={Airplane} alt=""/>
                        <img src={Letter} alt=""/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ContactUs;
