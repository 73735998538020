import Header from "./pages/Header/Header";
import Main from "./pages/Main/Main";
import AboutUs from "./pages/AboutUs/AboutUs";
import Career from "./pages/Career/Career";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./pages/Footer/Footer";
import OurMission from "./pages/OurMission/OurMission";

function App() {
    return (
        <div className="App">
            <Header />
            <Main />
            <AboutUs />
            <OurMission />
            <Career />
            <ContactUs />
            <Footer />
        </div>
  );
}

export default App;
