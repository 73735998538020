import cls from "./textField.module.css";
const TextField = ({ label, type, name, value, onChange, error }) => {
    const handleChange = ({ target }) => {
        console.log(target);
        onChange({ name: target.name, value: target.value });
    };

    return (
        <div>
            <input
                name={name}
                placeholder={label}
                type="text"
                id={name}
                value={value}
                onChange={handleChange}
                className={cls.textField}
            />
            {error && <div className="invalidFeedback">{error}</div>}
        </div>
    )
}

export default TextField;
