import cls from "./Header.module.css";
const Header = () => {
    return (
        <header className={cls.header}>
            <div className={`container container--main ${cls.headerContainer}`}>
                <div className={cls.header__logo} onClick={() => window.location.href = "#main"}>
                    <h1>Express Lab</h1>
                </div>

                <nav className={cls.header__menu}>
                    <li><a href="#aboutUs">Company</a></li>
                    <li><a href="#mission">Our mission</a></li>
                    <li><a href="#career">Careers</a></li>
                    <li><a href="#contact">Contacts</a></li>
                </nav>

            </div>
        </header>
    );
};

export default Header;
