import JobApplyForm from "../../components/JobApplyForm/JobApplyForm";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import cls from "./Career.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";


const Career = () => {
    return (
        <div id="career" className={cls.careerSection}>
            <div className="container container--main">
                <div className={cls.careerWrapper}>
                    <MarginBottomContainer margin={22}>
                        <Title title="Jobs at Socials" />
                    </MarginBottomContainer>

                    <MarginBottomContainer margin={63}>
                        <ParagraphSection>
                            Be with us and give joy to millions of people!
                        </ParagraphSection>
                    </MarginBottomContainer>
                    
                    <JobApplyForm />
                </div>
            </div>
        </div>
    );
};

export default Career;
