import Gamer from "../../shared/assets/images/Gamer.png";
import Arrow from "../../shared/assets/images/Arrow.png";
import cls from "./Main.module.css";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
import ScrollButton from "../../shared/ui/ScrollButton/ScrollButton";
const Main = () => {
    console.log(cls);
    return (
        <div id="main" className={cls.mainSection}>
            <div className="container container--main">
                <div className={cls.mainWrapper}>
                    <div className={cls.mainWrapper__info}>
                        <MarginBottomContainer margin={22}>
                            <Title title="Express Lab" />
                        </MarginBottomContainer>

                        <MarginBottomContainer margin={123}>
                            <ParagraphSection>
                                builds apps that you will want to use. We’re all about creating smart, user-friendly applications that make life easier and more fun. Whether it's for work, play, or something in between, we've got an app for that. Our team is dedicated to delivering quality and speed in every project we tackle.
                            </ParagraphSection>
                        </MarginBottomContainer>
                        <ScrollButton src={Arrow} href="aboutUs" label="ABOUT US" />

                    </div>
                    <div>
                        <img src={Gamer} alt=""/>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Main;
